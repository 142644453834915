<div id="head">
	<h1>
		Banners
		<button routerLink="/banners/form/0" class="btn btn-sm btn-primary">Aanmaken</button>
	</h1>
</div>

<div class="table-search mb-1">
	<input (keyup)="search($event, activitiesOriginal)">
</div>
<table class="table">
	<thead class="thead-dark">
		<tr>
			<th scope="col">Titel</th>
			<th scope="col">Actief voor</th>
			<th scope="col">Zichtbaar voor</th>
			<th scope="col">Start datum</th>
			<th scope="col">Eind datum</th>
			<th scope="col">Actie</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let banner of banners | paginate: paginationConfig; let i = index"
			[ngClass]="{ 'd-none': banner.hidden == true }">
			<td>
				{{banner.title}}
			</td>
			<td>
				<span class="badge mr-2" [ngClass]="{'badge-primary': banner.id == BANNER_STUDENT.id}">Studenten</span>
				<span class="badge" [ngClass]="{'badge-primary': banner.id == BANNER_TEACHER.id}">Docenten</span>
			</td>
			<td>
				<span class="badge mr-2" [ngClass]="{'badge-primary': banner.studentActive}">Studenten</span>
				<span class="badge" [ngClass]="{'badge-primary': banner.teacherActive}">Docenten</span>
			</td>
			<td>
				{{removeTime(banner.startAt)}}
			</td>
			<td>
				{{removeTime(banner.endAt)}}
			</td>
			<td><a class="edit-link" href="banners/form/{{banner.id}}">
					<i class="fas fa-edit"></i></a> &nbsp; <span class="delete-link" (click)="delete(banner)"><i
						class="fas fa-trash"></i></span></td>
		</tr>
	</tbody>
</table>

<pagination-controls class="pagination" (pageChange)="pageChanged($event)"></pagination-controls>