<h1>Controleer youtube videos</h1>
<div class="pagination">
    <font *ngFor="let section of sections; let i = index">
        <span
            class="page-select"
            [ngClass]="section['id'] == sectionIndex ? 'active' : ''"
            (click)="setCurrentSectionIndex(section['id'])"
            class="p-3"
            >{{ section["name"] }}</span
        >
    </font>
</div>

<div class="chapters-filter" *ngIf="chapters.length > 0">
    <h3>Hoofdstukken:</h3>
    <div class="form-group mb-2" *ngFor="let chapter of chapters">
        <input
            type="checkbox"
            (click)="addFilterValue(chapter.id)"
            name="{{ chapter.id }}"
            id="{{ chapter.id }}"
            class="mr-2"
        />
        <label for="{{ chapter.id }}">{{ chapter.name }}</label>
    </div>
</div>

<div class="table-search mb-1" *ngIf="sectionIndex">
    <input (keyup)="search($event)" />
</div>
<table class="table" *ngIf="sectionIndex">
    <thead class="thead-dark">
        <tr>
            <th scope="col">Video</th>
            <th scope="col" width="150">Les</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let video of videos; let i = index">
            <td><div [innerHTML]="getSanitizedHtml(video['video'])"></div></td>
            <td>
                <span *ngFor="let chapter of video['chapters']; let i = index">
                    <a
                        (click)="
                            visitLesson(
                                chapter['chapter']['chapterLessonId'],
                                video['lessonContent']['studyLevel']
                            )
                        "
                        style="text-decoration: underline"
                    >
                        {{ video["lesson"]["name"] }}
                        <small
                            >[{{ video["lessonContent"]["studyLevel"] }}]</small
                        >
                        ({{ chapter["chapter"]["name"] }})
                    </a>
                </span>
            </td>
        </tr>
    </tbody>
</table>
